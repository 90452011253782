<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.goodsManagerSubCategoriesAdd.mainForm" label-width="160px">
        <el-row :gutter="12">
          <el-col :md="24" :lg="6">
            <el-form-item :label="$t('form.product.online')" prop="online">
              <el-switch v-model="mainForm.data.online"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 商铺 -->
            <el-form-item :label="$t('form.common.shop')" prop="shop_id">
              <el-select
                v-model="mainForm.data.shop_id"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
                @change="handleMainFormShopSelectChange"
              >
                <el-option v-for="(item, index) in shopList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 中文名称 -->
            <el-form-item :label="$t('form.product.cnName')" prop="name">
              <el-input
                v-model="mainForm.data.name"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 英文名称 -->
            <el-form-item :label="$t('form.product.enName')" prop="">
              <el-input
                v-model="mainForm.data.name_en"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="24">
            <!-- 图片 -->
            <el-form-item :label="$t('form.product.image')" prop="remote_image_url">
              <importImg :limit="1" @change="handleMainFormImgChange" />
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 排序优先级 -->
            <el-form-item :label="$t('form.product.rankingPriority')" prop="">
              <el-input
                v-model="mainForm.data.rank"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="24">
            <!-- 分类 -->
            <el-form-item :label="$t('form.product.parentCategory')">
              <el-row v-for="(item, index) in mainForm.data.category_sub_categories_attributes" :key="index" class="mb-interval-tiny">
                <el-col :md="24" :lg="8" class="mr-interval-small mb-interval-tiny">
                  <el-select v-model="item.shop_category_id" class="width-fill" :placeholder="$t('placeholder.defaultSelect')" clearable filterable>
                    <el-option
                      v-for="(item, index) in categoriesList"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                      :loading="mainForm.categorieSelect.loading"
                      :disabled="
                        mainForm.data.category_sub_categories_attributes.find(_item => _item.shop_category_id === item.value) === undefined ? false : true
                      "
                      clearable
                      filterable
                    ></el-option>
                  </el-select>
                </el-col>
                <el-button type="danger" @click="() => handleMainFormSubCategoriesItemDelBtnClick(index)">{{ $t('button.delete') }}</el-button>
              </el-row>
              <el-row class="mb-interval-tiny">
                <el-button icon="el-icon-plus" @click="handleMainFormCategoriesAddBtnClick">{{ $t('button.add') }}</el-button>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
